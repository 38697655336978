export default class InsurancePayment {
  id!: number
  member_number!: number
  identification!: string
  insurance!: number
  coin_type!: string
  amount!: number
  method_payment!: number
  date!: Date
  period_from!: Date
  period_up_to!: Date
  observation!: string
  update_insurance = false
}
